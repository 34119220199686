import { BoxProps, Box } from "@mui/material";
import React from "react";

export const BoxColumn: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {children}
    </Box>
  );
};

//   sx={{
//     "& > *:not(first-of-type)": {
//       mb: 1,
//     },
//     ...sx,
//   }}
