import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { appClient } from "../apollo-client";
import theme from "../theme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "../createEmotionCache";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ExternalProvider, Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { AuthenticationProvider } from "../context/Authentication";
import { AppLayout } from "../components/Header";
import { DatabaseProvider } from "../context/Database";
const clientSideEmotionCache = createEmotionCache();

function getLibrary(provider: ExternalProvider) {
  return new Web3Provider(provider);
}

interface _AppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: _AppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={appClient}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <AuthenticationProvider>
              <DatabaseProvider>
                <AppLayout>
                  <Component {...pageProps} />
                </AppLayout>
              </DatabaseProvider>
            </AuthenticationProvider>
          </Web3ReactProvider>
        </ApolloProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
