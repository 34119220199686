import { Button, Box, IconButton } from "@mui/material";

import React from "react";

export const SocialIcons = () => (
  <Box>
    <IconButton href="http://discord.gg/ePMEwzGnvY" target="_blank">
      <img src="/discord_pixel_icon.png" alt="discord" style={{ width: 24 }} />
    </IconButton>
    <IconButton href="https://twitter.com/runes_tcg" target="_blank">
      <img src="/twitter_pixel_icon.png" alt="twitter" style={{ width: 24 }} />
    </IconButton>
    <IconButton href="https://forgottenrunes.com/wtf" target="_blank">
      <img src="/forgotten-runes-logo.png" alt="frwc" style={{ width: 72 }} />
    </IconButton>
  </Box>
);
export const Footer = () => {
  return <div>{<SocialIcons />}</div>;
};
