// NOTE: should be same as frontend/config.ts
export const isDevelopment = process.env.NODE_ENV === "development";
// set in frontend to connect to backend server instead of heroku, but still act as prod otherwise
export const isProdWithLocalBackend = isDevelopment ? false : false;
// set in backend to query local metadata server instead of cloud api, mimic new expected metadata
export const isProdWithLocalBackendAndMetadata = isDevelopment ? false : false;

export const CHAIN_ID_MUMBAI = 80001;
export const CHAIN_ID_LOCAL = 31337;
const CHAIN_ID_POLYGON = 137;
const CHAIN_ID_HEX_MUMBAI = "0x13381";
const CHAIN_ID_HEX_POLYGON = "0x89";
const CHAIN_ID_HEX_LOCAL = "0x7A69";

const CHAIN_NAME_MUMBAI = "Mumbai";
const CHAIN_NAME_POLYGON = "Polygon";
const CHAIN_RPC_MUMBAI = "https://matic-mumbai.chainstacklabs.com";
const CHAIN_RPC_POLYGON = "https://polygon-rpc.com";

export const METADATA_TOKEN_URI_CARDS =
  "https://us-central1-runes-337703.cloudfunctions.net/runes/cards/";
export const METADATA_TOKEN_URI_CARDPACKS =
  "https://us-central1-runes-337703.cloudfunctions.net/runes/card-packs/";

export const chainRPC = CHAIN_RPC_POLYGON;
export const chainName = CHAIN_NAME_POLYGON;

export const chainIdHex = isDevelopment
  ? CHAIN_ID_HEX_LOCAL
  : CHAIN_ID_HEX_POLYGON;

export const CARD_PACK_TOKEN_ID_STANDARD_CORE_SET = "0";
export const CARD_PACK_TOKEN_ID_SPECIAL_CORE_SET = "1";
export const CARD_PACK_TOKEN_ID_SOUL_SET = "2";

export const PRICE_CARD_PACK_STANDARD_WETH = "0.005";
export const PRICE_CARD_PACK_STANDARD_MATIC = "14";

export const PRICE_CARD_PACK_SPECIAL_WETH = "0.015";
export const PRICE_CARD_PACK_SPECIAL_MATIC = "41";

export const PRICE_CARD_PACK_SOUL_WETH = "0.01";
export const PRICE_CARD_PACK_SOUL_MATIC = "28";

const COLLECTION_NAME_TESTNET_BURNS_PROCESSED = "test-token-burns-processed";
const COLLECTION_NAME_POLYGON_BURNS_PROCESSED = "polygon-card-burns-processed";
export const collectionNameBurnsProcessed = isDevelopment
  ? COLLECTION_NAME_TESTNET_BURNS_PROCESSED
  : COLLECTION_NAME_POLYGON_BURNS_PROCESSED;

const COLLECTION_NAME_TESTNET_ENS = "test-ens";
const COLLECTION_NAME_POLYGON_ENS = "polygon-ens";
export const ensNames = COLLECTION_NAME_POLYGON_ENS;

const CONTRACT_ADDRESS_LOCAL_RUNES =
  "0x5FbDB2315678afecb367f032d93F642f64180aa3";
const CONTRACT_ADDRESS_TESTNET_RUNES =
  "0xB88B812434870836742547c1283bCCD79bCC2aA3";
const CONTRACT_ADDRESS_POLYGON_RUNES =
  "0x5aF7678C2aF78497c48340e42E34aF4546440e0D";

const CONTRACT_ADDRESS_LOCAL_MINTER_INITIAL =
  "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";
const CONTRACT_ADDRESS_TESTNET_MINTER_INITIAL =
  "0xdBA95A25902d62477DD20be84585cbb2e3f7aEf7";
const CONTRACT_ADDRESS_POLYGON_MINTER_INITIAL =
  "0x2E6a14AB106f89bfc66e2414439E049455789a45";

const CONTRACT_ADDRESS_LOCAL_CARD_PACK =
  "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0";
const CONTRACT_ADDRESS_TESTNET_CARD_PACK =
  "0x913505cD5563BD77d423416869ca7ab3B4A4cf05";
const CONTRACT_ADDRESS_POLYGON_CARD_PACK =
  "0x88B798b434100092048f81b7C4E10aB57C599321";

const CONTRACT_ADDRESS_LOCAL_CARD_PACK_MINTER =
  "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9";
const CONTRACT_ADDRESS_TESTNET_CARD_PACK_MINTER =
  "0x1400FB01CD51f72815554A72f1778d658Ba9D902";
const CONTRACT_ADDRESS_POLYGON_CARD_PACK_MINTER =
  "0xa960564832506f512231ca694BB6442FFee5A5dE";

const CONTRACT_ADDRESS_LOCAL_BACKGROUND =
  "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707";
const CONTRACT_ADDRESS_LOCAL_BACKGROUND_MINTER =
  "0x0165878A594ca255338adfa4d48449f69242Eb8F";
const CONTRACT_ADDRESS_TESTNET_BACKGROUND =
  "0xFaa24Facc7Ec11c8beaAFb6133316518798F0015";
const CONTRACT_ADDRESS_TESTNET_BACKGROUND_MINTER =
  "0x8171B8e35932343Baf3Bf28b98c34Facb9069546";
const CONTRACT_ADDRESS_POLYGON_BACKGROUND =
  "0x5E4BFaFc9FaeF68C57abC609E8BCf36B8Cbe74E6";
const CONTRACT_ADDRESS_POLYGON_BACKGROUND_MINTER =
  "0x64B0E653C3f4d7E44Cd8437a27e95F6eb66fdf5A";

const MORALIS_CHAIN_TESTNET = "mumbai";
const MORALIS_CHAIN_POLYGON = "polygon";

export const moralisChain = MORALIS_CHAIN_POLYGON;

const CONTRACT_ADDRESS_LOCAL_WETH =
  "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9";
// TESTNET_WETH is invalid, need to deploy our own ERC20 contract to mock WETH
const CONTRACT_ADDRESS_TESTNET_WETH = "";
const CONTRACT_ADDRESS_POLYGON_WETH =
  "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";

export const contractAddressRunes = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_RUNES
  : CONTRACT_ADDRESS_POLYGON_RUNES;

export const contractAddressMinterInitial = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_MINTER_INITIAL
  : CONTRACT_ADDRESS_POLYGON_MINTER_INITIAL;

export const contractAddressCardPack = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_CARD_PACK
  : CONTRACT_ADDRESS_POLYGON_CARD_PACK;
export const contractAddressCardPackMinter = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_CARD_PACK_MINTER
  : CONTRACT_ADDRESS_POLYGON_CARD_PACK_MINTER;

export const contractAddressWETH = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_WETH
  : CONTRACT_ADDRESS_POLYGON_WETH;

export const contractAddressBackground = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_BACKGROUND
  : CONTRACT_ADDRESS_POLYGON_BACKGROUND;
export const contractAddressBackgroundMinter = isDevelopment
  ? CONTRACT_ADDRESS_LOCAL_BACKGROUND_MINTER
  : CONTRACT_ADDRESS_POLYGON_BACKGROUND_MINTER;

export const chainId = isDevelopment ? CHAIN_ID_LOCAL : CHAIN_ID_POLYGON;
