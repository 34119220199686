
import React, { createContext, useContext } from "react";
import {
useQueryUserCardsQuery 
} from "../generated/graphql_schema";
import { ICard } from "../types";
import { useAuth } from "./Authentication";

export interface IDatabaseContext {
  refetchUserCards: () => void;
    userCards?: ICard[];
    isLoadingUserCards: boolean;
}

export const DatabaseContext = createContext<IDatabaseContext>({
    isLoadingUserCards: false,
    refetchUserCards: () => {}
});

export const DatabaseProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const {user} = useAuth()

  const shouldSkipUserCardsQuery = !user?.publicAddress

  const { data: userCardsData, loading: isLoadingUserCards, refetch: refetchUserCards} =
    useQueryUserCardsQuery({
      variables: { publicAddress: user?.publicAddress || "" },
      skip: shouldSkipUserCardsQuery
    });

  return (
    <DatabaseContext.Provider
      value={{
        userCards: userCardsData?.cards as ICard[],
        isLoadingUserCards,
        refetchUserCards
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => useContext(DatabaseContext)