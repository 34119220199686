import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { isDevelopment, isProdWithLocalBackend } from "./config";

const serverUrl =
  isDevelopment || isProdWithLocalBackend
    ? "http://localhost:8000/graphql"
    : "https://runes-card-game.herokuapp.com/graphql";
const websocketUrl =
  isDevelopment || isProdWithLocalBackend
    ? "ws://localhost:8000/subscriptions"
    : "wss://runes-card-game.herokuapp.com/subscriptions";

const httpLinkClient = createHttpLink({
  uri: serverUrl
});

interface Definition {
  kind: string;
  operation?: string;
}

const wsLink = process.browser
  ? new WebSocketLink({
      uri: websocketUrl,
      options: {
        reconnect: true,
        connectionParams: {
          authToken: localStorage.getItem("jwt")
        }
      }
    })
  : null;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const link = wsLink
  ? split(
      ({ query }) => {
        const { kind, operation }: Definition = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      httpLinkClient
    )
  : httpLinkClient;

// export const serverSideClient = new ApolloClient({
//   //   uri: "http://backend:8000",
//   cache: new InMemoryCache(),
//   link: authLink.concat(httpLinkServer),
// });

export const appClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link)
});
