import { Button, ButtonProps } from "@mui/material";
import Link from "next/link";

interface ILinkButtonProps extends ButtonProps {
  href: string; // URL for navigation
  children: React.ReactNode; // Content inside the button
}

export const LinkButton: React.FC<ILinkButtonProps> = ({
  children,
  href,
  ...buttonProps
}) => {
  return (
    <Link href={href}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  );
};