import { gql } from "@apollo/client";

// used for codegen
export const QUERY_USERS = gql`
  query users {
    users {
      id
      publicAddress
      ensName
    }
  }
`;

export const QUERY_USER = gql`
  query user($publicAddress: String!) {
    user(publicAddress: $publicAddress) {
      id
      publicAddress
      nonce
      joinedLobbyAt
      ensName
    }
  }
`;

export const SIGN_IN_USER = gql`
  mutation SignInUser($signature: String!, $publicAddress: String!) {
    signInUser(signature: $signature, publicAddress: $publicAddress) {
      jwt
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($publicAddress: String!) {
    createUser(publicAddress: $publicAddress) {
      nonce
    }
  }
`;

export const QUERY_USER_CARDS = gql`
  query queryUserCards($publicAddress: String!) {
    cards(publicAddress: $publicAddress)
  }
`;

// points to query backend/src/models/User.ts/cardPacks
export const QUERY_USER_CARD_PACKS = gql`
  query queryUserCardPacks($publicAddress: String!) {
    cardPacks(publicAddress: $publicAddress)
  }
`;

export const QUERY_USER_BACKGROUNDS = gql`
  query queryUserBackgrounds($publicAddress: String!) {
    backgrounds(publicAddress: $publicAddress)
  }
`;

export const QUERY_BURN_STATUS = gql`
  query queryBurnStatus($transactionHash: String!) {
    burnStatus(transactionHash: $transactionHash)
  }
`;

export const QUERY_IS_USER_ADMIN = gql`
  query isUserAdmin {
    isUserAdmin
  }
`