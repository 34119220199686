import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const colors = {
  text: "#fffffe",
  textDisabled: "#72757e"
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#7f5af0"
    },
    secondary: {
      main: "#72757e"
    },
    text: {
      primary: colors.text
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#16161a"
    }
  },
  components: {
    MuiAlert: {
      defaultProps: {
        variant: "filled"
      },
      styleOverrides: {
        root: {
          position: "absolute",
          top: 0,
          right: 0
        },
        message: {
          fontFamily: "Segoe UI"
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained"
      },
      styleOverrides: {
        root: {
          color: colors.text,
          textTransform: "none",
          fontSize: 18,
          ":disabled": {
            color: colors.textDisabled,
            // opacity: 0.5,
            cursor: "not-allowed",
            pointerEvents: "auto",
            background: "#0f0d1e"
          }
        },
        containedPrimary: {
          background: "#5b35d5"
        },
        containedSecondary: {
          background: "#0f0d1e",
          ":hover": {
            background: "#1f1e2f"
          },
          border: "1px solid #262343"
        },
        outlined: {
          borderColor: colors.text,
          ":hover": {
            borderColor: colors.text
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "black"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.text,
          "&.Mui-selected": {
            color: colors.text
          },
          opacity: 1
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.text
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: colors.text
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#7f5af0"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.text
            }
          }
        },
        notchedOutline: {
          borderColor: colors.text
        },
        input: {
          color: colors.text
        }
      }
    }
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       color: "white",
    //       outlineColor: "white"
    //     }
    //   }
    // }
  },
  typography: {
    body1: {
      color: colors.text,
      fontSize: "1.1rem",
      textShadow: "1px 1px 1px black"
    },
    h1: {
      color: colors.text
    },
    h2: {
      color: colors.text
    },
    h3: {
      color: colors.text
    },
    h4: {
      color: colors.text
    },
    h5: {
      color: colors.text
    },
    h6: {
      color: colors.text
    },
    fontFamily: ["'Alagard'"].join(",")
  }
});

export default theme;
